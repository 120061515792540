import { EnvironmentConfig } from '@app/interfaces/environment-config.interface';

export const ENVIRONMENT: EnvironmentConfig = {
  name: 'dev',
  production: false,
  useMock: false,
  appPlatform: {
    apiUrl: 'https://btf-on-boarding-dev-qa.heypay.cl/api/',
    siteKey: '6LfPJfIcAAAAAGnfVNgiuyuY_MAricdc1jiEv9SZ',
    firebaseConfig: {
      apiKey: 'AIzaSyABBU_iJeXdR0qKFeWdpxBwJhsK3tiMEy8',
      appId: '1:525212483858:web:374e413596ee3abe19b3cb',
      authDomain: 'btf-on-boarding-dev-qa.firebaseapp.com',
      databaseURL: 'https://btf-on-boarding-dev-qa.firebaseio.com',
      measurementId: 'G-ZNQS5ZN2NS',
      messagingSenderId: '525212483858',
      projectId: 'btf-on-boarding-dev-qa',
      storageBucket: 'btf-on-boarding-dev-qa.appspot.com'
    }
  },
  mixpanel: {
    token: 'a3ae55d46ac4757154b610c68d8ca81a'
  },
  defaultLocaleId: 'es-CL'
};
