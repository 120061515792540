import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate, UpdateAvailableEvent } from '@angular/service-worker';
import { Platform } from '@ionic/angular';

import { concat, interval, Observable } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PwaUpdateService {
  constructor(
    private appRef: ApplicationRef,
    private platform: Platform,
    private swUpdate: SwUpdate
  ) {
    this._setCheckForUpdates();
  }
  public async activatePwaUpdate(): Promise<void> {
    try {
      await this.swUpdate.activateUpdate();
      document.location.reload();
    } catch (error) {
      console.error('Error in PwaUpdateService._activateUpdate(), error: ', error);
    }
  }
  public checkForUpdates(): Observable<UpdateAvailableEvent> {
    return this.swUpdate.available;
  }
  public get isPwaAndSWUpdateEnabled(): boolean { return !this.platform.is('capacitor') && this.swUpdate.isEnabled; }
  private _setCheckForUpdates() {
    if (this.isPwaAndSWUpdateEnabled) {
      const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
      const everySixHours$ = interval(6 * 60 * 60 * 1000);
      const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);
      everySixHoursOnceAppIsStable$.subscribe(() => {
        this.swUpdate.checkForUpdate();
      });
    }
  }
}
