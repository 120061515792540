import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ENVIRONMENT } from '@app/config';
import { EnvironmentConfig } from '@app/interfaces/environment-config.interface';
import { HttpParams } from '@app/interfaces/http.interface';

@Injectable()
export class HttpInterceptor {
  private httpSuccessCode = 200;
  private headers = new HttpHeaders();
  private environment: EnvironmentConfig;

  constructor(private http: HttpClient) {
    this.environment = ENVIRONMENT;
  }

  public async mockedResponse(body: any): Promise<any> {
    const params = {
      status: this.httpSuccessCode,
      body
    };

    const response = new HttpResponse(params);
    return Promise.resolve(response.body);
  }

  public async get(url: string, mockup: any, customHeaders?: HttpHeaders, data?: any): Promise<any> {
    return this.httpCall({ method: 'get', url, mockup, data, customHeaders });
  }

  public async post(url: string, data: any, mockup: any, customHeaders?: HttpHeaders): Promise<any> {
    return this.httpCall({ method: 'post', url, mockup, data, customHeaders });
  }

  private async httpCall({ method, url, mockup, data, customHeaders }: HttpParams): Promise<any> {
    const header = customHeaders ? customHeaders : this.headers;

    if (this.environment.useMock) return this.mockedResponse(mockup);
    if (method === 'get') return this.http[method]<any>(url, { headers: header, params: data }).toPromise();

    return this.http[method]<any>(url, data, { headers: header }).toPromise();
  }
}
