import { Injectable } from '@angular/core';
const mixpanel = require('mixpanel-browser');

import { ENVIRONMENT } from '@app/config';
import { MixpanelServiceMock } from './mixpanel.service.mock';

export interface MixpanelServiceInterface {
  addToDefaultData(args: { [key: string]: any }): void;
  init(): void;
  setAlias(alias: string): void;
  setDefaultData(args: { [key: string]: any }): void;
  setPeople(args: { [key: string]: any }): void;
  track(eventName: string, properties: { [action: string]: any }): void;
}

@Injectable({
  providedIn: 'root',
  useClass: ENVIRONMENT.name === 'offline' ? MixpanelServiceMock : MixpanelService,
  deps: []
})

export class MixpanelService implements MixpanelServiceInterface {
  private _defaultData: {};
  private _initialized = false;

  public addToDefaultData(args: { [key: string]: any }): void {
    this._defaultData = { ...this._defaultData, ...args };
  }

  public init(): void {
    mixpanel.init(ENVIRONMENT.mixpanel.token);
    this._initialized = true;
  }

  public setAlias(alias: string): void {
    try {
      if (!this._initialized) {
        this.init();
      }
      mixpanel.alias(alias);
    } catch (error) {
      console.warn('Error in MixpanelService.setAlias()', error);
    }
  }

  public setDefaultData(args: { [key: string]: any }): void {
    this._defaultData = args;
  }

  public setPeople(args: { [key: string]: any }): void {
    try {
      if (!this._initialized) {
        this.init();
      }
      mixpanel.people.set(args);
    } catch (error) {
      console.warn('Error in MixpanelService.setPeople()', error);
    }
  }

  public track(eventName: string, properties: { [action: string]: any }): void {
    try {
      if (!this._initialized) {
        this.init();
      }
      mixpanel.track(eventName, { ...this._defaultData, ...properties });
    } catch (error) {
      console.warn('Error in MixpanelService.track()', error);
    }
  }
}
