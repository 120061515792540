export const ROUTES_WITH_RECAPTCHA_VALIDATION = [
  {
    route: 'api/enrollments',
    method: 'POST',
    action: 'enroll'
  },
  {
    route: 'api/sessions',
    method: 'POST',
    action: 'sessions'
  },
];
