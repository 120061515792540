import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'enrollment',
    loadChildren: () => import('./pages/enrollment/enrollment.module').then(m => m.EnrollmentPagesModule)
  },
  {
    path: 'enrollment-v2',
    loadChildren: () => import('./pages/enrollment-v2/enrollment.module').then(m =>m.EnrollmentPagesModule)
  },
  { path: '', redirectTo: 'enrollment-v2', pathMatch: 'full' },
  { path: '**', redirectTo: 'enrollment-v2' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
