import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ENVIRONMENT } from '@app/config';
import { ROUTES_WITH_RECAPTCHA_VALIDATION } from '@app/constants/recaptcha.constant';

@Injectable()
export class RecaptchaInterceptor implements HttpInterceptor {

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const matches = ROUTES_WITH_RECAPTCHA_VALIDATION.filter(
      (route) => this._matches(route.route, request.url) && route.method === request.method
    );
    if (matches.length === 1) {
      return from(this._getRecaptchaToken(matches[0].action))
        .pipe(
          switchMap((recaptchaToken: string) => {
            let requestClone;
            if (request.method === 'POST') {
              requestClone = request.clone({
                body: { ...request.body, recaptchaToken },
              });
            } else {
              requestClone = request.clone({
                headers: request.headers.append('recaptcha-token', recaptchaToken)
              });
            }
            return next.handle(requestClone);
          })
        );
    }
    return next.handle(request);
  }

  private _matches(route: string, requestUrl: string) {
    // TODO: Configure criteria to match services urls format
    const baseRequestUrlArray = requestUrl.replace('//', '').split('/');

    baseRequestUrlArray.shift();
    const endpoint = baseRequestUrlArray.join('/');

    return endpoint === route;
  }

  private _getRecaptchaToken(actionKey: string) {
    return (window as any).grecaptcha.execute(ENVIRONMENT.appPlatform.siteKey, { action: actionKey });
  }
}
