import { Component } from '@angular/core';
import { ActivationStart, NavigationEnd, Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { ENVIRONMENT } from '@app/config';
import { AnalyticsService } from '@services/analytics/analytics.service';
import { MixpanelService } from '@services/mixpanel/mixpanel.service';
import { PwaUpdateService } from '@services/pwa-update/pwa-update.service';
import { ToastService } from '@services/toast/toast.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private analyticsSvc: AnalyticsService,
    private mixpanelSvc: MixpanelService,
    private pwaUpdateSvc: PwaUpdateService,
    private router: Router,
    private toastSvc: ToastService,
    private translateSvc: TranslateService
  ) {
    this._initializeApp();
  }

  private _checkForPwaUpdates() {
    if (this.pwaUpdateSvc.isPwaAndSWUpdateEnabled) {
      this.pwaUpdateSvc.checkForUpdates()
        .subscribe(() => {
          this.toastSvc.showRaw('UPDATE_TOAST.MESSAGE', null, 'toast-success', 'UPDATE_TOAST.BUTTON', () => this.pwaUpdateSvc.activatePwaUpdate());
        }, (error) => {
          console.error('Error in AppComponent._checkForPwaUpdates(), error: ', error);
        });
    }
  }

  private _initializeApp() {
    this._checkForPwaUpdates();
    this.translateSvc.setDefaultLang(ENVIRONMENT.defaultLocaleId);
    this._setUpAnalyticsRouterEventsLogic();
    this._addRecaptchaScript();
    this._initMixpanel();
  }

  private _addRecaptchaScript() {
    const recaptchaSrc = (document: any, s: string, id: string, obj: any) => {
      // tslint:disable-next-line: prefer-const
      let js: any = null;
      const fjs = document.getElementsByTagName(s)[0];
      if (document.getElementById(id)) { return; }
      js = document.createElement(s); js.id = id;
      js.src = `https://www.google.com/recaptcha/api.js?render=${ENVIRONMENT.appPlatform.siteKey}`;
      fjs.parentNode.insertBefore(js, fjs);
    };
    recaptchaSrc(document, 'script', 'recaptcha-jssdk', this);
  }
  private _initMixpanel(): void {
    this.mixpanelSvc.init();
  }
  private _setUpAnalyticsRouterEventsLogic(): void {
    let routeValue: string, routeConfigPath: string, params: { [param: string]: string }, queryParams: { [param: string]: string };
    this.router.events.subscribe((event) => {
      if (event instanceof ActivationStart) { // this is to identify params and replace their values for their keys
        params = event.snapshot.params;
        queryParams = event.snapshot.queryParams;
        routeConfigPath = event.snapshot.routeConfig.path;
        routeValue = routeConfigPath;
        for (const key in params) {
          routeValue = routeValue.replace(`:${key}`, encodeURIComponent(params[key]));
        }
      }
      if (event instanceof NavigationEnd) {
        const url = routeValue && routeConfigPath ? event.urlAfterRedirects.replace(routeValue, routeConfigPath).split('?')[0] : event.urlAfterRedirects.split('?')[0];
        this.analyticsSvc.setCurrentScreen(url);
        this.analyticsSvc.logEvent('page_view', { ...params, ...queryParams, url });
        this.mixpanelSvc.addToDefaultData({ 'page_path': url });
      }
    });
  }
}
