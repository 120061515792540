import { MixpanelServiceInterface } from '@services/mixpanel/mixpanel.service';

export class MixpanelServiceMock implements MixpanelServiceInterface {
  private _defaultData: {};

  public addToDefaultData(args: { [key: string]: any }): void {
    this._defaultData = { ...this._defaultData, ...args };
  }

  public init(): void {
    console.log('Mixpanel init()');
  }

  public setAlias(alias: string): void {
    console.log('Mixpanel setAlias()', alias);
  }

  public setDefaultData(args: { [key: string]: any }): void {
    this._defaultData = args;
  }

  public setPeople(args: { [key: string]: any }): void {
    console.log('Mixpanel setPeople()', args);
  }

  public track(eventName: string, properties: { [action: string]: any }): void {
    console.log(`Mixpanel track() event ${eventName}`, { ...this._defaultData, ...properties });
  }
}
