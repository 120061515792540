import { AnalyticsServiceInterface } from '@services/analytics/analytics.service';

export class AnalyticsServiceMock implements AnalyticsServiceInterface {
  private _defaultData: {};

  public addToDefaultData(args: { [key: string]: any }): void {
    this._defaultData = { ...this._defaultData, ...args };
  }

  public logEvent(id: string, data: { [key: string]: string | number | boolean } = {}): Promise<any> {
    return Promise.resolve();
  }

  public setCurrentScreen(name: string): Promise<any> {
    return Promise.resolve();
  }

  public setUserId(id: string): Promise<any> {
    return Promise.resolve();
  }

  public setUserProperties(properties: { [key: string]: any }): Promise<any> {
    return Promise.resolve();
  }
}
