import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';

import { ENVIRONMENT } from '@app/config';
import { AnalyticsServiceMock } from './analytics.service.mock';

export interface AnalyticsServiceInterface {
  addToDefaultData(args: { [key: string]: any }): void;
  logEvent(id: string, data: { [key: string]: string | number | boolean }): Promise<any>;
  setCurrentScreen(name: string): Promise<any>;
  setUserId(id: string): Promise<any>;
  setUserProperties(properties: { [key: string]: any }): Promise<any>
}

@Injectable({
  providedIn: 'root',
  useClass: ENVIRONMENT.name === 'offline' ? AnalyticsServiceMock : AnalyticsService,
  deps: [AngularFireAnalytics]
})

export class AnalyticsService implements AnalyticsServiceInterface {
  private _defaultData: {};

  constructor(
    private analytics: AngularFireAnalytics
  ) { }


  public addToDefaultData(args: { [key: string]: any }): void {
    this._defaultData = { ...this._defaultData, ...args };
  }

  public logEvent(id: string, data: { [key: string]: string | number | boolean } = {}): Promise<any> {
    return this.analytics.logEvent(id, { ...this._defaultData, ...data }).catch((error) => {
      console.error('Unknown error in AnalyticsService.logEvent', error);
      return Promise.reject(error);
    });
  }

  public setCurrentScreen(name: string): Promise<any> {
    return this.analytics.setCurrentScreen(name).catch((error) => {
      console.error('Unknown error in AnalyticsService.setCurrentScreen', error);
      return Promise.reject(error);
    });
  }

  public setUserId(id: string): Promise<any> {
    return this.analytics.setUserId(id).catch((error) => {
      console.error('Unknown error in AnalyticsService.setUserId', error);
      return Promise.reject(error);
    });
  }

  public setUserProperties(properties: { [key: string]: any }): Promise<any> {
    return this.analytics.setUserProperties(properties).catch((error) => {
      console.error('Unknown error in AnalyticsService.setUserProperties', error);
      return Promise.reject(error);
    });
  }
}
