import { Injectable } from '@angular/core';

import { ToastController } from '@ionic/angular';

import { Translation, TranslationService } from '@services/translation/translation.service';

type ToastButtonAction = (value: any) => boolean | void | { [key: string]: any };

interface ToastButton {
  text: string;
  handler?: ToastButtonAction;
}

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  constructor(
    private toastCtrl: ToastController,
    private translationSvc: TranslationService
  ) { }

  /**
   * Creates an informative toast
   */
  public async show(messageKey: string | Translation, duration?: number, cssClass?: string): Promise<HTMLIonToastElement> {
    const message = await this.translationSvc.get(messageKey);
    return this._create(message, duration, cssClass);
  }

  /**
   * Creates an informative alert with only one option/button and raw values
   */
  public async showRaw(messageKey: string, duration?: number, cssClass?: string, buttonTextKey?: string, buttonAction?: ToastButtonAction): Promise<HTMLIonToastElement> {
    const message = await this.translationSvc.get(messageKey);
    const buttonText = await this.translationSvc.get(buttonTextKey);
    return this._create(message, duration, cssClass, [{ text: buttonText, handler: buttonAction }]);
  }

  private async _create(message: string, duration = 3000, cssClass: string, buttons?: (any | ToastButton)[]): Promise<HTMLIonToastElement> {
    const toast = await this.toastCtrl.create({ message, duration, cssClass, buttons });
    await toast.present();
    return toast;
  }
}
